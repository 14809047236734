<template>
  <div>
    <Edit ref="edit" :buttons="$buttons" />
    <v-card
      v-if="$isLogin && $isEmailVerified"
    >
      <v-card-title class="grey--text">
        Buttons
        <v-spacer/>
        <v-btn
          x-small
          class="grey--text mx-1"
          @click="openEdit"
        >
          edit
        </v-btn>
        <v-btn
          x-small
          class="grey--text mx-1"
          to="/docs/users-manual/binds"
        >
          guide
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-btn v-for="button in $buttons" :key="button.text" block rounded class="my-4" @click="buttonClick(button.text)">{{button.text}}</v-btn>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import Edit from '@/dialogs/button/Edit'
//import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf

export default {
  components:{
    Edit,
  },
  data: function () {
    return {
    }
  },
  methods: {
    async buttonClick(button){
      console.log("button",button)
      const url = sprintf("https://rhizosphere-sv.uedasoft.com/b/v1/pushcommand/%s", this.$hypha.id)
      var params = new URLSearchParams();
      params.append('command', "buttons/"+button+".sh");
      const res = await axios.post(url,params)
      if (res.status == 200) {
        console.log(sprintf("button %s completed"),button)
      } else {
        console.log(sprintf("button %s fail!"),button)
        console.log(res.status)
        console.log(res.data)
      }
    },
    openEdit(){
      this.$refs.edit.open()
    }
  },
  created(){
  /*
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log("user.uid",user.uid)
        console.log("this.$hypha.id",this.$hypha.id)
        let db = firebase.firestore();
        db.collection("users").doc(user.uid)
        .collection("hyphas").doc(this.$hypha.id)
        .collection("services").doc("buttons")
        .get().then(docSnapshot =>{
          if (docSnapshot.exists){
            console.log("docSnapshot",docSnapshot)
            console.log("docSnapshot.get(buttons)",docSnapshot.get("buttons"))
            this.$buttons = docSnapshot.get("buttons")
          }
        }).catch(err => alert(err))
      }
    })
    */
  }
}
</script>