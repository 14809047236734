<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
  >
    <v-card 
      v-if="$isLogin && $isEmailVerified"
    >
      <v-card-title class="grey--text">
        Edit Buttons
        <v-spacer/>
        <v-btn
          x-small
          class="grey--text mx-1"
          to="/docs/users-manual/binds"
        >
          guide
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-btn 
          v-for="button in btnCopied" :key="button.label" 
          block 
          rounded 
          class="my-4" 
        >
          <v-text-field 
            v-model="button.text" 
            :label="button.label"
            :placeholder="button.text" 
          />
        </v-btn>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="update"
        >
          update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
import "firebase/auth";
import axios from 'axios'
// https://github.com/alexei/sprintf.js
var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf
const clone = require('rfdc')()

export default {
  props: ['buttons'],

  components: {
  },
  data () {
    return {
      dialog: false,
      btnCopied: "",
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    cancel(){
      this.refreshButtons()
      this.dialog = false
    },
    update(){
      this.updateButtons()
      this.dialog = false
    },
    refreshButtons(){
      this.btnCopied = clone(this.buttons)
    },
    updateButtons(){
      this.$buttons = this.btnCopied

      var user = firebase.auth().currentUser;
      if (user) {
        user.getIdToken(/* forceRefresh */ true).then((idToken) => {
          const url = sprintf('https://rhizosphere-sv.uedasoft.com/b/v1/updatebuttons/%s/%s', this.$hypha.id, idToken)
          console.log("url",url)
          axios
          .post(url, this.buttons)
          .then(response => {
            if (response.status == 200) {
              console.log("status = ", response.status)
            }
          });
        });
      } 
    },
  },
  computed: {
    Bid(){
      if (this.bind){
        return this.bind.Bid
      } else {
        return ""
      }
    },
  },
  updated: function(){
  },
  watch: {
    buttons: function(newButton, oldButton) {
      console.log("newButton",newButton)
      console.log("oldButton",oldButton)
      this.btnCopied = clone(this.buttons)
    }
  }
}
</script>